import { FooterContainer } from "./styles";
import LogoIfollowBranco from "../../assets/logo-ifollow-branco.svg";
import wpp from "../../assets/whatsapp-svgrepo-com (1).svg";
import Instagram from "../../assets/instagram.svg";
import Linkedin from "../../assets/linkedin.svg";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";


const Footer = () => {
  return (
    <FooterContainer id="contato">
      <div className="content">
        <img
          style={{ width: "100px" }}
          src={LogoIfollowBranco}
          alt="logo branco"
        />
        <div className="left">
          <h1>Entre em contato!</h1>
          <p>
            +55 (11) 2476-2245<br></br>
            
            mkt@ifollow.com.br
          </p>
          <Link style={{color:'#00aeef', fontSize:15}} to={'/politica-privacidade'}>
            Politica-de-privacidade
          </Link>
           

        </div>
        <div className="center">
          <p>Rua Jandiatuba, 630 Sala 327 | Vila Andrade - São Paulo/SP</p>
        </div>
        <div className="right">
          <p>Acompanhe nossas redes sociais</p>
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/ifollowbrasil/"
              aria-label="Ir para Instagram"
            >
              <ReactSVG src={Instagram} alt="instragram" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://br.linkedin.com/company/ifollowbrasil"
              aria-label="Ir para Linkedin"
            >
              <ReactSVG src={Linkedin} alt="linkedin" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://api.whatsapp.com/send/?phone=5511950853443&text&type=phone_number&app_absent=0"
              aria-label="Ir para Linkedin"
            >
              <ReactSVG src={wpp} alt="linkedin" />
            </a>
          </div>
        </div>
      </div>
      <div className="footerCnpj">
        <div>
          <img
            style={{ width: "100px" }}
            src={LogoIfollowBranco}
            alt="logo branco"
          />
        </div>
        <p>
          iFollow Desenvolvimento e Licenciamento de Software LTDA | CNPJ
          34.574.801/0001-82
        </p>
      </div>
    </FooterContainer>
  );
};

export default Footer;
