import styled from 'styled-components';

export const ContainerGeral = styled.div`
    display:flex;
    flex-direction: column;

    
`;

export const ContainerConteudo = styled.div`
    margin-top: 2%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 2%;
    overflow: auto;
    h1{
        text-align: center;
    }
`;