import Footer from "../../components/Footer";
import ReactGA from 'react-ga';
import { ContainerGeral,ContainerConteudo} from "./style";
import { useEffect } from "react";

const PoliticaPrivacidade = () => {
    useEffect(()=>{
        ReactGA.pageview(window.location.pathname);
    },[])

    return (
        <ContainerGeral>
            <ContainerConteudo>
                <h1>
                POLÍTICA DE PRIVACIDADE
                </h1>
                <p>
                A Sit Assessoria e Marketing Ltda, pessoa jurídica de direito privado, com sede na Rua Jandiatuba, 506, Bloco B, Sala 622 - Vila Andrade, São Paulo / SP, inscrita no CNPJ/MF sob o nº 23.097.315/0001-02 (“Empresa” ou “nós”) leva a sua privacidade a sério e zela pela segurança e proteção de dados de todos os seus clientes, parceiros, fornecedores e usuários (“Usuários” ou “você”) do site “www.ifollow.com.br” e qualquer outro site, Loja, aplicativo operado pela Empresa (aqui designados, simplesmente, “Empresa”).
                Esta Política de Privacidade (“Política de Privacidade”) destina-se a informá-lo sobre o modo como nós utilizamos e divulgamos informações coletadas em suas visitas à nossa Empresa e em mensagens que trocamos com você (“Comunicações”).
                </p>
                <br/>
                <p>
                AO ACESSAR A EMPRESA, ENVIAR COMUNICAÇÕES OU FORNECER QUALQUER TIPO DE DADO PESSOAL, VOCÊ DECLARA ESTAR CIENTE E DE ACORDO COM ESTA POLÍTICA DE PRIVACIDADE, A QUAL DESCREVE AS FINALIDADES E FORMAS DE TRATAMENTO DE SEUS DADOS PESSOAIS QUE VOCÊ DISPONIBILIZAR NA EMPRESA.
                </p>

                <br/>
                <p>
                Esta Política de Privacidade fornece uma visão geral de nossas práticas de privacidade e das escolhas que você pode fazer, bem como direitos que você pode exercer em relação aos Dados Pessoais tratados por nós. Se você tiver alguma dúvida sobre o uso de Dados Pessoais, entre em contato com marketing@ifollow.com.br.
                </p>
                <br/>
                <p>
                Além disso, a Política de Privacidade não se aplica a quaisquer aplicativos, produtos, serviços, site ou recursos de mídia social de terceiros que possam ser oferecidos ou acessados por meio da Empresa. O acesso a esses links fará com que você deixe a Empresa e possa resultar na coleta ou compartilhamento de informações sobre você por terceiros. Nós não controlamos, endossamos ou fazemos quaisquer representações sobre esses sites de terceiros ou suas práticas de privacidade, que podem ser diferentes das nossas. Recomendamos que você revise a política de privacidade de qualquer site com o qual você interaja antes de permitir a coleta e o uso de seus Dados Pessoais.
                Caso você nos envie Dados Pessoais referentes a outras pessoas físicas, você declara ter a competência para fazê-lo e declara ter obtido o consentimento necessário para autorizar o uso de tais informações nos termos desta Política de Privacidade.
                </p>
            </ContainerConteudo>
            <Footer />
        </ContainerGeral>
    )
};

export default PoliticaPrivacidade ;
